// src/components/Galaxy/Galaxy.js
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
//import { GUI } from 'dat.gui';
import './Galaxy.css';
import styles from './Galaxy.module.css';

const Galaxy = () => {
    const canvasRef = useRef();

    useEffect(() => {
        const canvas = canvasRef.current;
        const renderer = new THREE.WebGLRenderer({ canvas });
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
        camera.position.set(3, 3, 3);
        scene.add(camera);

        const controls = new OrbitControls(camera, canvas);
        controls.enableDamping = true;

        // Begin original code integration
        //const gui = new GUI({ closed: true, width: 350 });

        const parameters = {
            count: 250000,
            radius: 5,
            branches: 5,
            spin: 1,
            randomness: 0.8,
            randomnessPower: 4,
            insideColor: "#ec5300",
            outsideColor: "#2fb4fc"
        };

        const vertexShader = `
  uniform float uSize;
  uniform float uTime;
  uniform float uHoleSize;

  attribute float aScale;
  attribute vec3 aRandomness;

  varying vec3 vColor;

  void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);

    // Spin
    float angle = atan(modelPosition.x, modelPosition.z);
    float distanceToCenter = length(modelPosition.xz) + uHoleSize;
    float uTimeOffset = uTime + (uHoleSize * 30.0);
    float angleOffset = (1.0 / distanceToCenter) * uTimeOffset * 0.2;
    angle += angleOffset;

    modelPosition.x = cos(angle) * distanceToCenter;
    modelPosition.z = sin(angle) * distanceToCenter;
    modelPosition.xyz += aRandomness;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;
    float scale = uSize * aScale;

    gl_PointSize = scale;
    gl_PointSize *= ( 1.0 / - viewPosition.z );
    vColor = color;
  }
`;

        const fragmentShader = `
  varying vec3 vColor;
  varying vec2 vUv;
  uniform sampler2D uTexture;

  void main() {
    gl_FragColor = vec4( vColor, 1.0 );
    gl_FragColor = gl_FragColor * texture2D(uTexture, vec2( gl_PointCoord.x, gl_PointCoord.y ) );
    gl_FragColor = gl_FragColor * vec4( vColor, 1.0 );
  }
`;
        const textureLoader = new THREE.TextureLoader();
        const starTexture = textureLoader.load(
            "https://assets.codepen.io/22914/star_02.png"
        );

        let geometry = null;
        let material = null;
        let points = null;

        const generateGalaxy = () => {
            if (points !== null) {
                geometry.dispose();
                material.dispose();
                scene.remove(points);
            }
            /**
             * Geometry
             */
            geometry = new THREE.BufferGeometry();

            const positions = new Float32Array(parameters.count * 3);
            const colors = new Float32Array(parameters.count * 3);
            const scales = new Float32Array(parameters.count);
            const randomness = new Float32Array(parameters.count * 3);
            const insideColor = new THREE.Color(parameters.insideColor);
            const outsideColor = new THREE.Color(parameters.outsideColor);

            for (let i = 0; i < parameters.count; i++) {
                const i3 = i * 3;

                // Position
                const radius = Math.random() * parameters.radius;

                const branchAngle =
                    ((i % parameters.branches) / parameters.branches) * Math.PI * 2;

                const randomX =
                    Math.pow(Math.random(), parameters.randomnessPower) *
                    (Math.random() < 0.5 ? 1 : -1) *
                    parameters.randomness *
                    radius;
                const randomY =
                    Math.pow(Math.random(), parameters.randomnessPower) *
                    (Math.random() < 0.5 ? 1 : -1) *
                    parameters.randomness *
                    radius;
                const randomZ =
                    Math.pow(Math.random(), parameters.randomnessPower) *
                    (Math.random() < 0.5 ? 1 : -1) *
                    parameters.randomness *
                    radius;

                positions[i3] = Math.cos(branchAngle) * radius;
                positions[i3 + 1] = 0;
                positions[i3 + 2] = Math.sin(branchAngle) * radius;

                // Randomness
                randomness[i3] = randomX;
                randomness[i3 + 1] = randomY;
                randomness[i3 + 2] = randomZ;

                // Color
                const mixedColor = insideColor.clone();
                mixedColor.lerp(outsideColor, radius / parameters.radius);

                colors[i3] = mixedColor.r;
                colors[i3 + 1] = mixedColor.g;
                colors[i3 + 2] = mixedColor.b;

                // Scales
                scales[i] = Math.random();
            }

            geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
            geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
            geometry.setAttribute("aScale", new THREE.BufferAttribute(scales, 1));
            geometry.setAttribute(
                "aRandomness",
                new THREE.BufferAttribute(randomness, 3)
            );
            // console.log(new THREE.)
            /**
             * Material
             */
            material = new THREE.ShaderMaterial({
                depthWrite: false,
                blending: THREE.AdditiveBlending,
                vertexColors: true,
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                transparent: true,
                uniforms: {
                    uTime: { value: 0 },
                    uSize: { value: 30 * renderer.getPixelRatio() },
                    uHoleSize: { value: 0.15 },
                    uTexture: { value: starTexture },
                    size: { value: 1.0 }
                }
            });

            /**
             * Points
             */
            points = new THREE.Points(geometry, material);
            scene.add(points);
            // The rest of the generateGalaxy function remains the same

        };
        /*
        // The rest of the GUI code remains the same
        gui
            .add(parameters, "count")
            .min(100)
            .max(1000000)
            .step(100)
            .onFinishChange(generateGalaxy)
            .name("Star count");
        gui
            .add(parameters, "radius")
            .min(0.01)
            .max(20)
            .step(0.01)
            .onFinishChange(generateGalaxy)
            .name("Galaxy radius");
        gui
            .add(parameters, "branches")
            .min(2)
            .max(20)
            .step(1)
            .onFinishChange(generateGalaxy)
            .name("Galaxy branches");
        gui
            .add(parameters, "randomness")
            .min(0)
            .max(2)
            .step(0.001)
            .onFinishChange(generateGalaxy)
            .name("Randomness position");
        gui
            .add(parameters, "randomnessPower")
            .min(1)
            .max(10)
            .step(0.001)
            .onFinishChange(generateGalaxy)
            .name("Randomness power");
        gui
            .addColor(parameters, "insideColor")
            .onFinishChange(generateGalaxy)
            .name("Galaxy inside color");
        gui
            .addColor(parameters, "outsideColor")
            .onFinishChange(generateGalaxy)
            .name("Galaxy outside color");
*/
        const onResize = () => {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        };

        window.addEventListener('resize', onResize);

        generateGalaxy();

        const clock = new THREE.Clock();

        const tick = () => {
            const elapsedTime = clock.getElapsedTime();

            material.uniforms.uTime.value = elapsedTime;

            controls.update();

            renderer.render(scene, camera);

            window.requestAnimationFrame(tick);
        };

        tick();

        return () => {
            window.removeEventListener('resize', onResize);
            controls.dispose();
        };
        // End original code integration

    }, []);

    return <canvas ref={canvasRef} className={styles.canvas}></canvas>;
};

export default Galaxy;
