// App.js
import React, { useState } from 'react';
import Galaxy from './components/Galaxy/Galaxy';
import Modal from './components/Modal/Modal';
import TypingEffect from "./components/TypingEffect/TypingEffect";
import './App.css';
import content from './modalContent';
import { Helmet } from 'react-helmet';

function App() {
    const [activeModal, setActiveModal] = useState(null);

    const handleModalToggle = (modalId) => {
        setActiveModal(activeModal === modalId ? null : modalId);
    };
    const hiddenClass = activeModal ? 'hidden' : '';
    const publishPapers = ['paper1', 'paper2', 'paper3', 'paper4'];
    const workingPapers = ['paper5'];

    return (
        <div className="relative w-full min-h-screen overflow-y-auto">
            <Galaxy />

            <div
                className="fadeIn text-gray-300 absolute top-0 left-0 w-full min-h-screen pt-14 pb-14 flex flex-col items-center justify-center animate-slide-in-from-top">
                <Helmet>
                    <title>Yun Pu</title>
                    <meta name="description" content="Yun Pu, Associate Professor at Southwestern University of Finance and Economics in Chengdu, China." />
                    <meta name="keywords" content="Economics, Finance, Market" />
                </Helmet>
                <h1 className={`text-5xl font-bold mb-5 ${hiddenClass}`}>Yun Pu</h1>
                <p className={`mx-4 md:mx-10 text ${hiddenClass}`}>
                    <a
                        href="mailto:puyun@swufe.edu.cn"
                        className="text-gray-300 hover:underline cursor-pointer"
                    >
                        puyun@swufe.edu.cn
                    </a>
                    &nbsp;or&nbsp;
                    <a
                        href="https://wj.qq.com/s2/10549486/2ba2/"
                        className="text-gray-300 hover:underline cursor-pointer"
                    >
                        Instant Messaging
                    </a>
                </p>
                <p className={`mx-4 md:mx-10 text ${hiddenClass}`}>
                    Associate Professor</p>
                <p className={`mx-4 md:mx-10 text ${hiddenClass}`}>
                    School of Public Finance and Taxation</p>
                <p className={`mx-4 md:mx-10 text ${hiddenClass}`}>
                    Southwestern University of Finance and Economics
                    Chengdu, China</p>
                <h2 className={`text-3xl font-semibold mb-3 mt-6 hidden`}>Working Papers</h2>

               <h2 className={`text-3xl font-semibold mb-3 mt-6 ${hiddenClass}`}>Selected Recent & Upcoming Publications</h2>
                <div className={`mx-8 md:mx-14 text-justify ${hiddenClass}`}>
                    <ul>
                        <li className={`text-xl ${hiddenClass ? '' : 'list-disc'} pl-4`}>
                            {<span className={`text-left text-xl`}>{content["paper5"].beforeClickText}</span>}
                            &nbsp;
                            (<button className="hover:underline" onClick={() => {
                            window.open("https://dx.doi.org/10.2139/ssrn.3779962", '_blank');
                        }}>
                            link
                        </button>)
                        </li>
                    </ul>
                </div>
                <div className="mx-8 md:mx-14 text-justify">
                    <ul>
                        {Object.keys(content)
                            .filter((key) => publishPapers.includes(key))
                            .map((key, index) => (
                                <li key={key} className={`text-xl ${hiddenClass ? '' : 'list-disc'} pl-4`}>
                                    <Modal
                                        id={key}
                                        activeModal={activeModal}
                                        beforeClickText={<span className={`text-left text-xl`}>{content[key].beforeClickText}</span>}
                                        hiddenClass = {hiddenClass}
                                        afterClickText={<TypingEffect text={content[key].afterClickText} className="text-justify w-full max-w-full" />}
                                        onClick={handleModalToggle}
                                        externalLink = {content[key].externalLink}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
                <footer className="absolute bottom-0 w-full px-4 py-4 flex items-center justify-center bg-transparent">
                    <p className="text-gray-300 text-xs">Designed by Yun Pu • Copyright © 2023 • Last updated on April 25, 2023</p>
                </footer>
            </div>

        </div>
    );
}

export default App;
