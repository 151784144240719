// modalContent.js
const content = {
    paper1: {
        beforeClickText: (<span>"College Admissions in Three Chinese Provinces: Boston Mechanism vs. Deferred Acceptance Mechanism," <em>China Economic Review</em> 67 (2021): 101622.</span>),
        afterClickText: `Thhis paper compares the empirical performance of the Boston Mechanism (BM) and the Deferred Acceptance Algorithm (DA) in college admissions in China. The author constructs a model of the BM and estimates the attractiveness of Chinese colleges in three provinces, then uses counterfactuals to compare the BM and DA in these provinces. The main findings show that the BM is superior to the DA in terms of total welfare, and most students suffer from the switch from the BM to DA. The total welfare of students has decreased by 1.73% to 6.63% due to the switch, which would require a significant improvement in college quality to compensate. The paper contributes to the literature by providing evidence that the BM is a better approach than the DA for conducting college admissions in China from a social welfare perspective, extending the analysis to the strict priorities scenario relevant for the Chinese context, and presenting a model that uses public data, making it more accessible for further research. In conclusion, the paper finds that the BM outperforms the DA in college admissions in China, raising the question of whether the BM is the best possible mechanism and suggesting that future studies could explore new mechanisms that outperform the BM or prove that the BM yields the highest welfare in college admissions in China.`,
        externalLink: "https://doi.org/10.1016/j.chieco.2021.101622"
    },
    paper2: {
        beforeClickText: (<span>"A recursive algorithm for student selection of schools in a constrained serial dictatorship mechanism," <em>Applied Economics</em> 53.21 (2021): 2412-2415.</span>),
        afterClickText: "Thhis paper introduces a more efficient recursive algorithm for student school selection using a constrained school choice (CSC) method of allocation, which is faster than traditional methods that compare every combination of schools. The recursive algorithm is beneficial for simulation, estimation, and counterfactual analysis of a CSC mechanism. The author then applies this algorithm to study whether increasing the number of available school choices improves student allocation in Guangxi province, China. The results demonstrate that providing more available choices does not significantly impact student school selection, as the total utility of students decreases marginally when increasing the number of choices from 3 to 6 and 6 to 9. This finding indicates that the current number of available choices is sufficient, and increasing them would be a waste of resources. The proposed algorithm could enhance empirical studies in school admissions and contribute to the understanding of the effectiveness of reforms in increasing available choices for students.",
        externalLink: "https://doi.org/10.1080/00036846.2020.1861204"
    },
    paper3: {
        beforeClickText: (<span>"Where are the fundamental traders? A model application based on the Shanghai Stock Exchange," with Carl Zulauf, <em>Emerging Markets Review</em> 49 (2021): 100775.</span>),
        afterClickText: "Thhe paper introduces a model for estimating the proportionate wealth of momentum, contrarian, and fundamental traders in the stock market, specifically examining the Shanghai Stock Exchange (SSE). The study finds that the SSE is weak-form efficient, indicating that technical traders cannot earn abnormal profits even though most traders are technical. The market's maturity is considered low due to the smaller proportion of fundamental traders. These findings are valuable for policymakers who can use the model to monitor market maturity, portfolio managers who should be aware of the high impact of technical trading on price, and investors who can save time and money by recognizing that seemingly profitable price patterns are unprofitable.",
        externalLink: "https://doi.org/10.1016/j.ememar.2020.100775"
    },
    paper4: {
        beforeClickText: (<span>"College Admission in Three Chinese Provinces: Province-Specific versus Pooling Quotas," <em>China Economic Review</em> 60 (2020): 101299.</span>),
        afterClickText: "Thhis paper examines the effects of admission quotas among provinces in China using data from three provinces over two years. The author finds that pooling admission quotas across provinces improves student welfare, and the performances of the Boston Mechanism (BM) and Deferred Acceptance Mechanism (DA) become more similar after pooling. The current province-specific quota system leads to unfairness among provinces due to the geographical allocation of top colleges. The study suggests that the government should switch to a pooling quota system to enhance student welfare, reduce the performance gap between BM and DA, and address the unfairness caused by the current system. Although the research is limited to three provinces and two years, it provides valuable insights into the potential benefits of a pooling quota system in China's college admissions process, which could guide future studies and policy recommendations.",
        externalLink: "https://doi.org/10.1016/j.chieco.2019.04.007"
    },
    paper5: {
        beforeClickText: (<span>"Direct-to-Consumer Sales and Bargaining," with Javier Donna, Pedro Pereira, Andre Trindade and Renan Yoshida. Forthcoming in <strong><em>RAND Journal of Economics</em></strong></span>),
        afterClickText: "",
        externalLink: "https://doi.org/10.1016/j.chieco.2019.04.007"
    },

};

export default content;
