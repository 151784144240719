import React, { useState, useEffect } from 'react';

const TypingEffect = ({ text, className = '' }) => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            if (index < text.length - 1) {
                setDisplayText((prevText) => prevText + text[index]);
                index++;
            } else {
                clearInterval(interval);
            }
        }, 20);

        return () => {
            clearInterval(interval);
        };
    }, [text]);

    return (
        <p className={className}>
            {displayText}
        </p>
    );
};

export default TypingEffect;
