import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Modal = ({ id, activeModal, beforeClickText, afterClickText, onClick, externalLink, hiddenClass}) => {
    const isModalOpen = activeModal === id;

    const handleModalToggle = () => {
        onClick(id);
    };

    const handleExternalLink = () => {
        window.open(externalLink, '_blank');
    };

    return (
        <>
            {!isModalOpen && (
                <div className="transition duration-500 ease-in-out">
                    <p className={hiddenClass}>{beforeClickText}
                        &nbsp;(

                        <button  className="hover:underline" onClick={handleModalToggle}>
                         summary
                    </button>, &nbsp;


                    <button className="hover:underline" onClick={handleExternalLink}>
                        link
                    </button>)</p>
                </div>
            )}
            {isModalOpen && (
                <div className="relative mx-2 md:mx-10 my-10">
                    <div className="">
                        <span>{afterClickText}</span>
                    </div>
                    <button
                        className="absolute top-3 left-10 md:top-10 md:left-3 transform -translate-x-10 -translate-y-10"
                        onClick={handleModalToggle}
                    >
                        <ArrowBackIosNewIcon />
                    </button>
                </div>
            )}
        </>
    );
};

export default Modal;
